<template lang="pug">
	div(class="contact")
		div(class="row")
			div(class="col s12")
				separator(text="Kontakt")

		div(class="row")
			div(class="col s12")
				form-block(teaser="Hast du Interesse an Dagobert oder Fragen an uns? Lass von dir hören!" source="Kontakt Seite" v-bind:form_config="formConfig" submit_label="Abschicken" endpoint="https://website-backend.dagorbert-apps.de/mails/relay" form_submitted_message="Vielen Dank!<br/>Wir melden uns so schnell wie möglich!")

</template>

<script>
import Separator from '@/components/Separator';
import Form from '@/components/Form';

export default {
	components: {
		'separator': Separator,
		'form-block': Form
	},
	data: function() {
		return {
			'formConfig': [
				{
					'label': 'Dein Anliegen',
					'key': 'topic',
					'type': 'textarea',
					'required': true
				},
				{
					'label': 'Unternehmen',
					'key': 'company',
					'type': 'text',
					'required': true
				},
				{
					'label': 'Name',
					'key': 'contact_name',
					'type': 'text',
					'required': true
				},
				{
					'label': 'E-Mail Adresse',
					'key': 'contact_email',
					'type': 'email',
					'required': true
				},
				{
					'label': 'Telefonnummer',
					'key': 'phone',
					'type': 'text',
					'required': true
				},
				{
					'label': 'Erwartetes Ticketvolumen pro Jahr',
					'key': 'volume',
					'type': 'number',
					'required': false
				}
			]
		}
	}
}
</script>